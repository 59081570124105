import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './CSS.css'

import cello from '../Mediats/30253.jpeg'


const ThreePage =() =>{

    return (

        <Container className='textcont'>


            <Row className='textcont'>
                <Col>
                    <h2>
                    Après une courte année de violon,
                    j'ai commencer le violoncelle en 2012,
                    après ma formations intensive à la wild code school qui m'a demander l'entièreté de ma concentration,
                    j'ai reprit les cours qui ce sont transformer en sessions d'improvisations accompagner au piano par ma prof  
                    </h2>
                </Col>
                <Col>
                    <img src={cello} alt='cello' />
                </Col>
            </Row>
            <Row>
                <Col>
                    {/* <p>
                        le possible c'est fait,
                        l'impossible est en prod,
                        pour le miracle, prévoir 48 heurs
                    </p> */}
                </Col>
                <Col>
                    {/* <h5>
                        si il y a loin de la coupe aux lèvres,
                        mais il y a près de 200Km de paris à
                        montargis
                    </h5> */}
                </Col>
            </Row>
        </Container>

    )



}
export default ThreePage;