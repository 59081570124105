import React from 'react'


const NoPage =() =>{

    return (

        <div>

            <h4>
                what you doing here ??
            </h4>
     
        </div>

    )



}
export default NoPage;