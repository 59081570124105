import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './CSS.css'


const TwoPage =() =>{

    return (

        
        <Container className='textcont'>
            <Row>
                <Col>
                <iframe src="https://docs.google.com/presentation/d/1g0OsgpjaX8ee2plm81_co6kHRtFRLFLZ9FVhQK9J6PI/embed?start=false&loop=false&delayms=5000" frameborder="0" width="960" height="569" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true">
                </iframe>
                </Col>

            </Row>
            <Row className='intline textcont'>
                <p>
                ------------------------
                </p>
                
            </Row>
            {/* <Row className='textcont'>
                <Col>
                    <h2>
                        texte
                    </h2>
                </Col>
                <Col>
                    <h5>
                        projet Kitchen Cat
                    </h5>
                </Col>
            </Row> */}
        </Container>
    )



}
export default TwoPage;