import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './CSS.css'

const HomePage =() =>{

    return (

    <div>

        <Container className='textcont'>
            <Row>
                <Col>
                    <p>
                        ce site est déstiner à faire ma présentation, d'une manière
                        un peut plus complètte qu'un CV au format vu et revu, 
                        incluent les trois points conseillé, les septs erreurs a ne pas faire,
                        et je ne sait quel poncifs que candidats et recruteurs connaissent par coeur.
                    </p>
                </Col>
                <Col>
                    {/* <h5>
                        si il y a loin de la coupe aux lèvres,
                        mais il y a près de 200Km de paris à
                        montargis
                    </h5> */}
                </Col>
            </Row>
            <Row className='intline textcont'>
                <p>

                </p>
                
            </Row>
            <Row className='textcont'>
                <Col>
                    {/* <p>
                        le possible c'est fait,
                        l'impossible est en prod,
                        pour le miracle, prévoir 48 heurs
                    </p> */}
                </Col>
                <Col>
                    <h5>
                        Au sujet des coquilles orthographique, je vous envoie au 
                        premier paragraphe de la séction " About ", je suis un vieux dyslexique
                        au max de c'est compètences en franais, matière bien trop illogique pour mon cêrveau
                    </h5>
                </Col>
            </Row>
        </Container>
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NKWGFJ9J"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
     
    </div>

    )



}
export default HomePage;