import React from 'react'
// import { Link } from 'react-router-dom';
// import Footer from '../components/Footer/Footer'

import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader';
import 'bootstrap/dist/css/bootstrap.css';
// import './CSS.css'

import dyslexia from '../Mediats/dyslexie.jpg'
import diplome from '../Mediats/diplome.png'
import Docto from '../Mediats/Doctolib.png'
import imdb from '../Mediats/imdb.jpg'
import keolis from '../Mediats/keolis.jpg'
import sql from '../Mediats/sql.jpg'
import topos from '../Mediats/topos.jpg'
import wild from '../Mediats/maxresdefault.jpg'
import ligair from '../Mediats/logo_ligair.jpg'



const AboutPage = () => {

    return (

        <div className='place'>

            <h4>
         
            </h4>
            {/* <Link to="/"class="button big gray" >Go back to home</Link> */}
        
            <Container>
                <Row>
                    <Col>
                        {/* <h3 className='color'>Vous vous demandez comment je suis arriver là ?</h3> */}
                        <div class="alert alert-info" role="alert">
                            <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                <h3>Expèrience et infos</h3></Accordion.Header>
                                    <Accordion.Body>
                                        <Container>
                                            <div class="alert alert-info" role="alert">
                                                <Row>
                                                    <Col>
                                                        <Accordion>
                                                            <AccordionHeader>< img className='dysico' src={dyslexia} alt='dyslexie' /> dyslexie </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                Si la vue de «  coquilles » dans un texte et les terminaisons raté vous insupportes, je puis vous assurez que je fait du mieux que je le puis, mais quittez là, la suite pourrait s'avérer quelque peut traumatisant .
                                                                Toute fois je vous laisse ce lien vers une play liste Youtube <a href="https://www.youtube.com/watch?v=VmaxajlqvRU&list=PLqhlzPL6Evk3EbJ-cmU-vWrcFkFYBK8ih"  alt="Youtube Link">Je parle Françe</a>
                                                                bonne journée </h6>
                                                            </Accordion.Body>
                                                        </Accordion>
                                                    </Col>
                                                    <Col>
                                                        <Accordion>
                                                            <AccordionHeader>< img className='ico' src={diplome} alt='dyslexie' />  diplme </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                Mon seul et unique diplôme est un certificat de conducteur de chien, obtenue lors de mon service militaire dans l'armée de l'aire dans le corps des fusillés commandos -section cynophile -
                                                                le pourquoi se trouve a gauche de ceci .</h6>
                                                            </Accordion.Body>
                                                        </Accordion>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Container>
                                        <Container>
                                            <div class="alert alert-secondary" role="alert">
                                                <Row>
                                                    <Col>
                                                        <Accordion>
                                                        <Accordion.Item eventKey="0">
                                                            <AccordionHeader>1994 - 2000 </AccordionHeader>
                                                            <Accordion.Body><h4>
                                                                            Moteur Câbleur en courant faible
                                                                             </h4>
                                                                             <h6>
                                                                             Je me suis rapidement spécialisé dans le réseau informatique ( Rj45 ) 
                                                                             puis Fibre optique avant de me sédentariser en prenant en charge la gestions 
                                                                             du magasin et la préparation du matériel pour les équipes sur le terrain .
                                                                             </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                            <Accordion.Item eventKey="2">
                                                            <AccordionHeader>2004 - 2009 </AccordionHeader>
                                                            <Accordion.Body><h4>
                                                                            divers missions d'intérims
                                                                             </h4>
                                                                             <h6>
                                                                             Intérim dans le secteur du câblage courant faible, mission longue pour AEI et Péage Parck.
                                                                             Mission d'un mois pour British Telecom au sein de EADS ( qui reviendras en 2010 ; 2011 et 2012 )
                                                                             </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                            <Accordion.Item eventKey="4">
                                                            <AccordionHeader>2011 - 2019 </AccordionHeader>
                                                            <Accordion.Body><h4>
                                                                            Petit travaux et formations
                                                                             </h4>
                                                                             <h6>
                                                                             Intérim et formation d'agent de production
                                                                             ( une discutions avec une formatrice m'a poussé vers une 
                                                                             formation dans le domaine du développement informatique )
                                                                             </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>
                                                    </Col>
                                                    <Col>
                                                        <Accordion>
                                                            <Accordion.Item eventKey="1">
                                                                <AccordionHeader>2000 - 2004</AccordionHeader>
                                                                <Accordion.Body><h4>
                                                                                Gestion de stock  Préparation de commande 
                                                                                </h4>
                                                                                <h6>
                                                                                Gestion du stock de 1500 pièces destiné à la fabrication d'enceintes a haute contraintes,
                                                                                préparation de commandes clients de pièces et prototypes ainsi que la prise en charge de 
                                                                                cible almuminium pour évaporation sous vide ;
                                                                                </h6>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                            <Accordion.Item eventKey="3">
                                                                <AccordionHeader>2009 - 2011</AccordionHeader>
                                                                <Accordion.Body><h4>
                                                                                Gâtinais Emploi 
                                                                                </h4>
                                                                                <h6>
                                                                                Aide au jardinage et bricolage dans le cadre de l'association .
                                                                                </h6>
                                                                </Accordion.Body>
                                                                </Accordion.Item>
                                                        </Accordion>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Container>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header> Wild Code School</Accordion.Header>
                                    <Accordion.Body>
                                    <Container>
                                            <div class="p-3 mb-2 bg-danger text-white">
                                                <Row>
                                                
                                                    <Col>
                                                        <Accordion>
                                                        <Accordion.Item eventKey="0">
                                                            <AccordionHeader>< img className='ico3' src={sql} alt='My SQL' /> Toy's and Models </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                            Création d’un dashboard à partir d’une base de données SQL
                                                                            Création d’un outil d’aide à la décision 
                                                                             </h6>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="2">
                                                            <AccordionHeader>< img className='ico' src={keolis} alt='Keolis Orléans' /> Projet clients </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                            Automatiser le traitement et regroupement de données. Traitement de trois
                                                                            bases de données, et visualisation par power BI
                                                                            </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                            <Accordion.Item eventKey="4">
                                                            <AccordionHeader>< img className='ico4' src={wild} alt='Datathon-1' /> Datathon </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                            Recherche et exploitation de bases de données relatives aux
                                                                            élections municipales dont l’objectif est de déterminer le ratio
                                                                            homme/femme parmi les élus.

                                                                             </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>
                                           
                                                    </Col>
                                                    <Col>
                                                        <Accordion>
                                                        <Accordion.Item eventKey="1">
                                                            <AccordionHeader>< img className='ico' src={imdb} alt='IMDB database' /> IMDB recommandations </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                            Analyse et exploitation d’un dataset de recommandations de films
                                                                            Création d’un modèle de machine learning
                                                                            </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                            <Accordion.Item eventKey="3">
                                                            <AccordionHeader>< img className='ico' src={ligair} alt='lig_air' /> Projet clients </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                            création d’un outils web de calcule de consommation / pollution, rendu par
                                                                            graphique selon data-set client 
                                                                            </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                            <Accordion.Item eventKey="5">
                                                            <AccordionHeader>< img className='ico' src={Docto} alt='Datathon-2' /> Doctolib & dataîku </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                            Recherche et exploitation de bases de données publiques et relatives à la santé. 
                                                                            </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>
                                                    </Col>
                                                </Row>
                                            </div>                                            
                                        </Container>
                                        <Container>
                                            <div>
                                                <Row>
                                                    <Col>
                                                        <Accordion>
                                                        <Accordion.Item eventKey="6">
                                                            <AccordionHeader>< img className='ico' src={topos} alt='Topos urbanisme' /> stage en entreprise </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                            Recherche de thèmes et prescriptions légale pare mots clés 
                                                                            </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>
                                                    </Col>
                                                    {/* <Col>
                                                        <Accordion>
                                                            <AccordionHeader>< img className='ico' src={ligair} alt='lig_air' /> Projet clients </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                            Recherche de thèmes et prescriptions légale pare mots clés 
                                                                            </h6>
                                                            </Accordion.Body>
                                                        </Accordion>
                                                    </Col> */}
                                                </Row>
                                            </div>                                            
                                        </Container>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header> En Suite </Accordion.Header>
                                    <Accordion.Body>
                                    <Container>
                                            <div>
                                                <Row>
                                                
                                                    <Col>
                                                        <Accordion>
                                                        <Accordion.Item eventKey="0">
                                                            <AccordionHeader> 2021 </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                                  j'ai continuer a travailler sur Django et React,  
                                                                                  "_Will be Back_"
                                                                             </h6>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="2">
                                                            <AccordionHeader> Maison D'automne </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                                création d'une station de contrôle
                                                                                environementale intèrieur et extèrieur.                                                                                
                                                                            </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                            <Accordion.Item eventKey="4">
                                                            <AccordionHeader> Analyse </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                                Analyse des data
                                                                            </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>
                                           
                                                    </Col>
                                                    <Col>
                                                        <Accordion>
                                                        <Accordion.Item eventKey="1">
                                                            <AccordionHeader> 2022 </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                                je commence a travailler sur raspberry pi 4, ESP32
                                                                            </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                            <Accordion.Item eventKey="3">
                                                            <AccordionHeader> découverte  </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                                j'éxplore le système Raspberry PI
                                                                                , Arduino et ESP32
                                                                            </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                            <Accordion.Item eventKey="5">
                                                            <AccordionHeader> Developpement </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                                developpements de server Django et React
                                                                                pour l'entrainement .
                                                                            </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>
                                                    </Col>
                                                    <Col>
                                                        <Accordion>
                                                        <Accordion.Item eventKey="1">
                                                            <AccordionHeader> 2023 </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                                le visite chez ma vétérinaire
                                                                                me décide à lancer le projet KitCat
                                                                            </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                            <Accordion.Item eventKey="3">
                                                            <AccordionHeader> 2023 Projet KitCat </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                                prise en main des systémes PICO
                                                                                et développement MicroPython
                                                                            </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                            <Accordion.Item eventKey="5">
                                                            <AccordionHeader> création de site </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                                développement de ce site et sur utilisation
                                                                                de l'accordeon dans la page 'CV'
                                                                            </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>
                                                    </Col>
                                                    <Col>
                                                        <Accordion>
                                                        <Accordion.Item eventKey="1">
                                                            <AccordionHeader> 2024 </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                                Prise de contactes en vue de passé une vétérinaire
                                                                                c'est plus compliqué que prévue.
                                                                            </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                            <Accordion.Item eventKey="3">
                                                            <AccordionHeader> 2024 Projet KitCat </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                                finnalisation des balances, création des systèmes
                                                                                KitCat-1701-A et B
                                                                            </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                            <Accordion.Item eventKey="5">
                                                            <AccordionHeader> Maison d'Automne </AccordionHeader>
                                                            <Accordion.Body><h6>
                                                                                Câblage solide de " Torlna " la station météo
                                                                                avec l'ajout du détecteur de foudre
                                                                            </h6>
                                                            </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>
                                                    </Col>
                                                </Row>
                                            </div>                                            
                                        </Container>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Col>
                </Row>
            </Container>

        </div>

    )



}
export default AboutPage;