
import React from 'react';
import './CSS.css'
import {
  Nav,
  NavLink,
  //Bars,
  NavMenu,
  // NavBtn,
  // NavBtnLink,
} from './NavbarElements';



const Navbar = () => {
  return (
    <>
      <Nav>
        {/* <Bars /> */}
  
        <NavMenu>
          <NavLink to='/' activeStyle>
              <img className='logo' src='./logo.png' alt='logo'/>
          </NavLink>

          {/* <NavLink to='/About' activeStyle>
              Torlna
          </NavLink> */}
          <NavLink to='/PageOne' activeStyle>
              Formations
          </NavLink>
          <NavLink to='/Pagetwo' activeStyle>
              Projets
          </NavLink>
          <NavLink to='/Pagethree' activeStyle>
              hobby
          </NavLink>
          <NavLink to='/About' activeStyle>
              About
          </NavLink>
          <NavLink to='*' activeStyle>
          </NavLink>

          {/* <NavLink to='/blogs' activeStyle>
            Blogs
          </NavLink>
          <NavLink to='/sign-up' activeStyle>
            Sign Up
          </NavLink>
          {/* Second Nav  */}
         {/* <NavBtnLink to='/sign-in'>Sign In</NavBtnLink> */}
        </NavMenu>
        {/* <NavBtn>
          <NavBtnLink to='/Login'>Sign In</NavBtnLink> 
        </NavBtn> */}
      </Nav>
    </>
  );
};
  
export default Navbar;