import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './CSS.css'


const OnePage =() =>{

    return (

        <Container className='textcont intline'>
                    <Row>
                        <Col>
                            <h5>
                            Depuis ma découverte du code sur TO8 et plus tard avec le jeu ColoBot,
                            une passion pour la programmation s’est éveillée en moi,
                            une passion que je cultive encore aujourd'hui.
                            </h5>
                        </Col>

                    </Row>

                    <Row className='textcont'>
                        <Col>
                            <h5>
                            Ma véritable initiation à la programmation s’est concrétisée en 2020 à la Wild Code School.
                            Malgré un contexte chaotique, l'équipe pédagogique a su s’adapter avec brio.
                            Bien que je n'aie pas obtenu le titre professionnel tant convoité,
                            cette expérience a ravivé en moi une ferveur qui continue de nourrir ma créativité.
                            </h5>
                        </Col>

                    </Row>

                    <Row className='textcont'>
                        <Col>
                            <h5>
                            Cette formation de Data Analyst et Développeur Web m’a permis d’acquérir des compétences solides dans ces deux domaines.
                            En 2021, j’ai décidé de prolonger cette formation de manière autodidacte à travers un projet phare :
                            Maison d'Automne. Ce projet m’a offert l'opportunité de combiner mes compétences en analyse de données,
                            ainsi qu'en développement back-end et front-end.
                            </h5>
                        </Col>

                    </Row>
                                   
                    <Row className='textcont'>
                        <Col>
                            <h5>
                            Face à la complexité d’intégrer les données de ma station météo Netatmo avec celles de la consommation électrique efergy
                            pour réaliser une analyse combinée et prédictive via une page web, j'ai choisi de me plonger dans les systèmes Arduino.
                            </h5>
                        </Col>

                    </Row>
                    <Row className='textcont'>
                        <Col>
                            <h5>
                            Ainsi, Maison d'Automne – un jeu de mots inspiré de "Autonomous Home" (devenu "Autumn House", soit "Maison d'Automne")
                            – est devenu un projet véritablement autonome. J'ai développé des modules de surveillance de l’atmosphère intérieure
                            et de la consommation électrique, ainsi qu'une station météo basée sur un microcontrôleur ESP32. Doté d'une antenne Wi-Fi,
                            l'ESP32 permet à ces modules de transmettre leurs données à un serveur Django hébergé localement sur un Raspberry Pi 4,
                            où elles sont traitées. Seul le site web React est accessible en ligne.
                            </h5>
                        </Col>

                    </Row>
                    <Row className='textcont'>
                        <Col>
                            <h5>
                            Mon autoformation se poursuit à travers divers projets annexes, de tailles et d’importances variées, tels que Kitchen Cat,
                            MaGTube, ou encore PICO BattleShip, que vous pouvez retrouver dans la section Projets.
                            </h5>
                        </Col>

                    </Row>
                </Container>
        
            )



}
export default OnePage;